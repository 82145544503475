import React from 'react'
import 'regenerator-runtime'
import {createRoot} from 'react-dom/client'
import apiConfig from './api-client/apiConfig'
import LoadingText from './components/LoadingText'
import './styles/LoadingText.css'
import {Auth0Provider} from '@auth0/auth0-react'
import {PostHogProvider} from 'posthog-js/react'
import {PostHogConfig} from 'posthog-js'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.addEventListener('vite:preloadError', _event => {
	window.location.reload() // for example, refresh the page
})

const App = React.lazy(() => import('./App'))

const options: Partial<PostHogConfig> = {
	api_host: 'https://eu.i.posthog.com',
	person_profiles: 'always',
	enable_heatmaps: true,
	persistence: 'localStorage+cookie',
	autocapture: true,
	rageclick: true,
	session_recording: {
		maskAllInputs: false,
		maskInputOptions: {
			password: true,
		},
	},
}

const Main: React.FC = () => {
	return (
		<React.StrictMode>
			<React.Suspense fallback={<LoadingText />}>
				<Auth0Provider
					domain={apiConfig.domain}
					clientId={apiConfig.client_id}
					authorizationParams={{
						redirect_uri: `${window.location.origin}/callback`,
						audience: apiConfig.audience,
						scope: apiConfig.scope,
					}}
					cacheLocation="localstorage"
				>
					<PostHogProvider
						apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
						options={options}
					>
						<App />
					</PostHogProvider>
				</Auth0Provider>
			</React.Suspense>
		</React.StrictMode>
	)
}

const app = document.querySelector('#app')
if (app) createRoot(app).render(<Main />)
