import React from 'react'
import {DotPulse} from '@uiball/loaders'

const LoadingText: React.FC = () => {
	return (
		<div className="loading-text">
			<h1 className="loading-text-heading">Vahterus Sales Cloud</h1>
			<div className="spinner">
				<DotPulse size={35} color="black" />
				<div className="spinner-text">Loading ...</div>
			</div>
		</div>
	)
}

export default LoadingText
